<template>
  <div class="shortDetail">
    <div class="back-operate">
        <van-image
            width="24"
            height="24"
            :src="require('@/assets/images/back.png')"
            @click="onClickLeft"
            class="van-image"
        />
        <span>短推详情</span>
    </div>
    <div class="shortDetail-scroll">
        <div style="padding: 10px; padding-top: 0;">
            <div class="tab-con">
                <van-tabs v-model="activeName" @click="tabClick" color="#23C5B8">
                    <van-tab name="1" title="短推信息" id="shortInfo" style="overflow: auto;">
                        <div class="task-detail">
                            <div class="car-people">
                                <p class="ordinary-p">
                                    <span class="title">保险公司</span>
                                    <span class="title-con">{{ taskDetail.insCompanyName }}</span>
                                </p>
                                <p class="ordinary-p">
                                    <span class="title">车牌号</span>
                                    <span class="title-con">{{ taskDetail.plateNo }}</span>
                                </p>
                                <p class="ordinary-p">
                                    <span class="title">联系电话</span>
                                    <span class="title-con">{{ taskDetail.reportPhone }}</span>
                                </p>
                                <p class="ordinary-p">
                                    <span class="title">车主名称</span>
                                    <span class="title-con">{{ taskDetail.reportName }}</span>
                                </p>
                                <p class="ordinary-p">
                                    <span class="title">报案时间</span>
                                    <span class="title-con">{{ dayjs(taskDetail.reportTime).format('YYYY-MM-DD HH:mm:ss')}}</span>
                                </p>
                                <p class="ordinary-p">
                                    <span class="title">报案号</span>
                                    <span class="title-con">{{ taskDetail.reportNo }}</span>
                                </p>
                                <p style="margin-top: 18px; height: auto; line-height: 20px; margin-bottom: 16px;">
                                    <span class="title" style="vertical-align: top; position: relative; top: -2px;">备注</span>
                                    <span class="title-con" style="width: calc(100% - 70px); display: inline-block; vertical-align: top; line-height: 18px;">{{ taskDetail.description }}</span>
                                </p>
                                <p style="height: auto; line-height: 32px; position: relative; top: -10px;">
                                    <span class="title" style="vertical-align: top;">短信内容</span>
                                    <span class="title-con" style="vertical-align: top;" @click="toCopy">
                                        <van-image
                                            width="20"
                                            height="20"
                                            :src="require('@/assets/images/copy.svg')"
                                            style="vertical-align: middle;"
                                        />
                                    </span>
                                </p>
                                <p style="line-height: 8px; height: unset; text-align: justify; position: relative; top: -10px;">
                                    <span class="title-con" id="copyCon">
                                        {{ taskDetail.smsContent }}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </van-tab>
                    <van-tab id="followRecord" style="overflow: auto;" name="2" title="跟进记录">
                        <div class="follow-record">
                            <van-steps direction="vertical" v-if="followRecord.length > 0">
                                <van-step v-for="(item, ind) in followRecord" :key="ind">
                                    <div class="follow-content">
                                        <p class="ordinary-p" style="margin-bottom: -6px;">
                                            <span class="title">跟进时间：</span>
                                            <span>{{ dayjs(item.trackTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
                                        </p>
                                        <p class="ordinary-p">
                                            <span class="title">跟进专员：</span>
                                            <span>{{ item.userName }}</span>
                                            <TagTip style="background: #37B8AD; color: #fff; position: absolute; right: 24px; width: 60px; margin-top: 4px;">
                                                <template #tipval>
                                                    <span>{{ dictFilter('REPAIR_SMS_TRACK_STATUS', item.trackStatus) }}</span>
                                                </template>
                                            </TagTip>
                                        </p>
                                        <p class="ordinary-p" style="line-height: unset; height: auto;">
                                            <span class="title">跟进记录：</span>
                                            <span class="title-con" style="display: inline-block; vertical-align: top; width: calc(100% - 86px); word-break: break-all;">
                                                {{ item.trackContent }}
                                            </span>
                                        </p>
                                        <p class="ordinary-p">
                                            <span class="title">下次跟进日期：</span>{{ item.nextTrackDate && dayjs(item.nextTrackDate).format('YYYY-MM-DD') }}
                                        </p>
                                    </div>
                                </van-step>
                            </van-steps>
                            <p v-else style="font-size: 13px; text-align: center; margin-top: 30%;">
                                还没有跟进记录，快去联系客户吧！
                            </p>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
        </div>
        <div class="bottom-btn">
            <van-button round block type="info" color="#15AEA2" class="shortDetail-btn" @click="follow">跟进</van-button>
        </div>
    </div>
    <FollowForm ref="FollowForm" :id="id" :smsContent="taskDetail.smsContent" @updateList="updateList" />
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { mapState } from "vuex";
import { request } from "@/api/service";
import * as clipboard from 'clipboard-polyfill'
import { Toast } from 'vant'
import FollowForm from './module/followForm.vue'
import { isArray } from "lodash";
import store from '@/store'
import TagTip from '@/components/TagTip.vue'
export default {
	name:'Follow',
	components: { FollowForm, TagTip },
	data() {
		return {
            activeName: '',
            activeValue: '1',
            priceName: [0],
            id: '',
            taskDetail: {},
            followRecord: [],
            priceRecord: [],
            userList: []
		}
	},
	computed: {
        ...mapState("dict", ["dictMap"])
    },
	created(){
        store.dispatch('dict/loadDict')
        this.id = this.$route.query.id
        this.getDetail(this.id)
	},
	mounted () {
        shortInfo.style.height = (document.documentElement.clientHeight - 150) + 'px'
        followRecord.style.height = (document.documentElement.clientHeight - 150) + 'px'
    },
	methods:{
		dayjs,
        search () {
            this.$refs.HYList.pageForm.page = 1
            this.$refs.HYList.onLoadSource()
        },
        onClickLeft () {
            this.$router.go(-1)
        },
        getDetail (id) {
            request({
                url: `/afis-renewal/repair/repairSms/info/${id}`,
                method: "get",
                data: {
            }
            }).then(res => {
                this.taskDetail = res
            })
            this.getFollowRecord(id)
        },
        dictFilter (dicts, value) {
            const filterPro = !this.dictMap[dicts]
                ? []
                : this.dictMap[dicts].filter(dict => {
                return dict.value === value
                })
            if (filterPro && filterPro.length >= 1) {
                return filterPro[0].label
            } else return value
        },
        follow () {
            this.$refs.FollowForm.show = true
            this.$refs.FollowForm.init()
        },
        toCopy () {
            // clipboard.writeText(this.taskDetail.smsContent)
            
                let element = document.getElementById('copyCon')
                const range = document.createRange();
                range.selectNode(element); // 设定range包含的节点对象
                /* 窗口的selection对象，表示用户选择的文本 */
                const selection = window.getSelection();
                if (selection.rangeCount > 0) selection.removeAllRanges(); // 将已经包含的已选择的对象清除掉
                selection.addRange(range); // 将要复制的区域的range对象添加到selection对象中
                document.execCommand('copy'); // 执行copy命令，copy用户选择的文本

            Toast.success('复制成功')
        },
        updateList (id) {
            this.getFollowRecord(id)
        },
        getFollowRecord (id) {
            request({
                url: `/afis-renewal/repair/repairSmsTrack/queryTrackList/${id}`,
                method: "post",
                data: {
                }
            }).then(res => {
                this.followRecord = res
            })
        },
        tabClick () {
            this.getDetail(this.id)
        }
	}
}
</script>
<style lang="scss" scoped>
.shortDetail {
    p {
        margin: 0;
        padding: 0;
    }
    background: linear-gradient(#00A194, #fff);
    // .shortDetail-scroll {
    //     overflow: auto;
    // }
    .van-tabs__content {
        height: 100%;
        overflow: auto;
    }
    .back-operate {
        padding-top: 14px;
        height: 24px;
        line-height: 24px;
        padding-left: 10px;
        text-align: center;
        padding-bottom: 4px;
        .van-image {
            position: absolute;
            left: 10px;
        }
        span {
            color: #fff;
            font-size: 17px;
            vertical-align: top;
        }
    }
    .van-nav-bar ::v-deep {
        background: transparent;
        color: #fff;
    }
    .van-nav-bar__title {
        color: #fff !important;
    }
    
    .van-icon-arrow-left ::v-deep {
        color: #fff;
    }
    .bottom-btn {
        position: fixed;
        bottom: 0;
        height: 60px;
        line-height: 60px;
        padding-left: 64%;
        background: #fff;
        .shortDetail-btn {
            width: 3rem;
            display: inline-block;
            height: 44px;
            position: relative;
            bottom: 16px;
            right: 50px;
        }
    }
    .car-info {
        background: #fff;
        border-radius: 6px;
        padding: 10px;
        .car-time {
            display: flex;
            justify-content: space-between;
            height: 32px;
            line-height: 32px;
            border-bottom: 1px solid #E5E5E5;
            .plat-no {
                font-size: 23px;
            }
        }
        .date-btn ::v-deep {
            width: 100px;
            height: 26px;
            background-color: #fff;
            color: #35B8AD;
            font-size: 12px;
            display: inline-block;
            border-color: #35B8AD;
        }
        .name-time {
            display: flex;
            justify-content: space-between;
            height: 30px;
            line-height: 30px;
            color: #909090;
            font-size: 13px;
        }
    }
    .tab-con ::v-deep {
        background: #fff;
        border-radius: 6px;
        padding: 10px;
        .ordinary-p {
            height: 32px;
            line-height: 32px;
        }
        .task-detail {
            margin-top: 10px;
            .car-people {
                .title {
                    display: inline-block;
                    width: 70px;
                    font-size: 13px;
                    color: #8A8989;
                }
                .title-con {
                    font-size: 13px;
                }
            }
        }
        .follow-record {
            .time {
                font-size: 12px;
                color: #8A8989;
            }
            .follow-content {
                font-size: 12px;
                color: #000;
                .title {
                    color: #8A8989;
                    display: inline-block;
                    width: 86px;
                }
                .btn {
                    display: inline-block;
                    width: 110px;
                    height: 19px;
                    font-size: 12px;
                    position: absolute;
                    right: 0px;
                }
            }
            .van-step__title {
                background: #F2F3F4;
                border-radius: 0px 8px 8px 8px;
                padding: 0.3rem 0.2rem 0.1rem 0.3rem;
            }
        }
        .collapse-head {
            .title-con {
                font-size: 16px;
                color: rgb(255, 82, 40);
            }
        }
    }
}
</style>